import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const canvas = document.getElementById("our-video");

if (canvas) {
    const context = canvas.getContext("2d");

    canvas.width = 1920;
    canvas.height = 1080;

    const firstIdx = 0;
    const frameCountTotal = 466;
    const frameCount = frameCountTotal - firstIdx; // 485 / 25 = 19.4 sec

    const images: any[] = []
    const ourVideo = {
        frame: 0
    };

    let tl = gsap.timeline({paused: true});

    const render = () => {
        if (images.length && ourVideo.frame < frameCount) {
            context.clearRect(0, 0, canvas.width, canvas.height);
            context.drawImage(images[ourVideo.frame], 0, 0);
        }

        tl.progress(ourVideo.frame / frameCount);
    }

    tl.to(ourVideo, {
        frame: frameCount - 1,
        snap: "frame",
        ease: "power2",
        scrollTrigger: {
            scrub: 1,
        },
        onUpdate: render
    });


    const framesToSeconds = (frames: number) => (frames - firstIdx) / 25;

    // Text tweens

    tl
        .to('.dummy', {f: 0, duration: framesToSeconds(frameCountTotal)})

        .to('.text-01', {opacity: 1, duration: 0.3, ease: 'power2'}, framesToSeconds(1))
        .to('.text-01', {opacity: 0, duration: 0.3, ease: 'power2'}, framesToSeconds(20))

        .to('.text-02', {opacity: 1, duration: 0.3, ease: 'power2'}, framesToSeconds(25))
        .to('.text-02', {opacity: 0, duration: 0.3, ease: 'power2'}, framesToSeconds(55))

        .to('.text-03', {opacity: 1, duration: 0.3, ease: 'power2'}, framesToSeconds(62))
        .to('.text-03', {opacity: 0, duration: 0.3, ease: 'power2'}, framesToSeconds(92))

        .to('.text-04', {opacity: 1, duration: 0.3, ease: 'power2'}, framesToSeconds(105))
        .to('.text-04', {opacity: 0, duration: 0.3, ease: 'power2'}, framesToSeconds(132))

        .to('.text-05', {opacity: 1, duration: 0.3, ease: 'power2'}, framesToSeconds(145))
        .to('.text-05', {opacity: 0, duration: 0.3, ease: 'power2'}, framesToSeconds(180))

        .to('.text-05', {opacity: 1, duration: 0.3, ease: 'power2'}, framesToSeconds(145))
        .to('.text-05', {opacity: 0, duration: 0.3, ease: 'power2'}, framesToSeconds(190))

        .to('.text-06', {opacity: 1, duration: 0.3, ease: 'power2'}, framesToSeconds(210))
        .to('.text-06', {opacity: 0, duration: 0.3, ease: 'power2'}, framesToSeconds(253))

        .to('.text-07', {opacity: 1, duration: 0.3, ease: 'power2'}, framesToSeconds(333))
        .to('.text-07', {opacity: 0, duration: 0.3, ease: 'power2'}, framesToSeconds(375))

        .to('.text-08', {opacity: 1, duration: 0.3, ease: 'power2'}, framesToSeconds(385))
        .to('.text-08', {opacity: 0, duration: 0.3, ease: 'power2'}, framesToSeconds(425))
    ;

    const currentFrame = (idx: number) => `/assets/frames/ff${(idx + 1).toString().padStart(3, '0')}.jpg`;

    let img = new Image();
    images.push(img);
    img.onload = render;
    img.src = currentFrame(firstIdx);

    for (let i = firstIdx + 1; i < frameCount; i++) {
        const img = new Image();
        img.src = currentFrame(i);
        images.push(img);
    }

}
